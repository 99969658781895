.header-color {
  background: #de6161; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #de6161; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img {
  height: 250px;
  padding-top: 5em;
}

.banner-text {
  background-color: black;
  opacity: 0.8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h2 {
  font-size: 66px;
  font-weight: bold;
  color: white;
  margin: auto;
  padding: 8px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  margin: auto;
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.projects-grid {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}

/* contact page css */
.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 66%;
  height: 100%;
  background: white;
  color: black;
  margin-bottom: 30px;
}

.contact-grid h2 {
  font-family: "Anton", sans-serif;
}

.contact-grid p {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 80%;
  margin: auto;
}

.contact-list i {
  font-size: 100px;
  padding-right: 15rem;
  width: 100%;
}

/* resume page css*/

.resume-right-col {
  background: #27221f;
  color: white;
  padding: 2em;
}

.resume-right-col h2 {
  text-align: left;
}
